<template>
    <v-container fluid>
        <page-banner links="Ma formation/Accompagnement"></page-banner>

        <formation-header></formation-header>

        <v-row v-for="eduObj in educationalObjectives" :key="eduObj.id" class="mt-10">
            <v-col>
                <v-row class="mt-15">
                    <v-card class="pinkred pa-0" tile elevation="0" height="32px">
                        <v-card-title class="text-h5 pa-0">
                            <v-col md="11" class="pa-0"><v-icon class="mx-5" color="brown">fas fa-sort-amount-down-alt</v-icon>{{ eduObj.name }}</v-col>
                            <v-col class="pa-0">{{ eduObj.tutoringDuration | durationToHuman }}</v-col>
                        </v-card-title
                        >
                    </v-card>
                </v-row>

                <v-row>
                    <v-col offset-md="2" md="8">
                        <v-row v-for="opeObj in getOperationalObjectivesByParentId(eduObj.id)" :key="opeObj.id">
                            <v-col>
                                <v-row class="mt-5">
                                    <v-col md="11" class="pa-0"><v-icon color="pinkred" class="mr-4">fas fa-grip-lines</v-icon>{{ opeObj.name }}</v-col>
                                    <v-col class="pa-0">{{ opeObj.tutoringDuration | durationToHuman }}</v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-row v-for="speObj in getSpecificObjectivesByParentId(opeObj.id)" :key="speObj.id" class="mb-6">
                                            <v-col>
                                                <v-row>
                                                    <v-card class="pinkred lighten-1">
                                                        <v-card-title>Tâches tutorales (intervention formateur)</v-card-title>
                                                    </v-card>
                                                </v-row>
                                                <v-row>
                                                    <v-card class="pinkred lighten-2">
                                                        <v-card-text>
                                                            <v-row>
                                                                <v-col md="3">
                                                                    <span class="text-subtitle-1 ml-5">Objectif formation</span>
                                                                </v-col>
                                                                <v-divider vertical></v-divider>
                                                                <v-col md="8">{{ speObj.name }}</v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-row>
                                                <v-row>
                                                    <v-card>
                                                        <v-card-text>
                                                            <v-row>
                                                                <v-col md="3">
                                                                    <span class="text-subtitle-1 ml-5">Objectif</span>
                                                                </v-col>
                                                                <v-divider vertical></v-divider>
                                                                <v-col md="8">{{ speObj.TutoringTask.goal }}</v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-row>
                                                <v-row>
                                                    <v-card>
                                                        <v-card-text>
                                                            <v-row>
                                                                <v-col md="3">
                                                                    <span class="text-subtitle-1 ml-5">Réponse(s)</span>
                                                                </v-col>
                                                                <v-divider vertical></v-divider>
                                                                <v-col md="8">{{ speObj.TutoringTask.answer }}</v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-row>
                                                <v-row>
                                                    <v-card>
                                                        <v-card-text>
                                                            <v-row>
                                                                <v-col md="3">
                                                                    <span class="text-subtitle-1 ml-5">Durée</span>
                                                                </v-col>
                                                                <v-divider vertical></v-divider>
                                                                <v-col md="8">{{ speObj.TutoringTask.duration | durationToHuman }}</v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-row>
                                                <v-row>
                                                    <v-card>
                                                        <v-card-text>
                                                            <v-row>
                                                                <v-col md="3">
                                                                    <span class="text-subtitle-1 ml-5">Acteur(s)</span>
                                                                </v-col>
                                                                <v-divider vertical></v-divider>
                                                                <v-col md="8">
                                                                    <v-select
                                                                        class="textStyleInput"
                                                                        placeholder="Acteurs"
                                                                        :value="speObj.TutoringTask.actor"
                                                                        @change="updateValue(speObj.TutoringTask.id, 'actor', $event)"
                                                                        :items="actorList"
                                                                    ></v-select>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-row>
                                                <v-row>
                                                    <v-card>
                                                        <v-card-text>
                                                            <v-row>
                                                                <v-col md="3">
                                                                    <span class="text-subtitle-1 ml-5">Fonction tutorale</span>
                                                                </v-col>
                                                                <v-divider vertical></v-divider>
                                                                <v-col md="8">{{ speObj.TutoringTask.function }}</v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-row>
                                                <v-row>
                                                    <v-card>
                                                        <v-card-text>
                                                            <v-row>
                                                                <v-col md="3">
                                                                    <span class="text-subtitle-1 ml-5">Plan de support</span>
                                                                </v-col>
                                                                <v-divider vertical></v-divider>
                                                                <v-col md="8">{{ speObj.TutoringTask.supportPlan }}</v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-row>
                                                <v-row>
                                                    <v-card>
                                                        <v-card-text>
                                                            <v-row>
                                                                <v-col md="3">
                                                                    <span class="text-subtitle-1 ml-5">Fréquence/Position</span>
                                                                </v-col>
                                                                <v-divider vertical></v-divider>
                                                                <v-col md="8">{{ speObj.TutoringTask.position }}</v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-row>
                                                <v-row>
                                                    <v-card>
                                                        <v-card-text>
                                                            <v-row>
                                                                <v-col md="3">
                                                                    <span class="text-subtitle-1 ml-5">Modalité(s)</span>
                                                                </v-col>
                                                                <v-divider vertical></v-divider>
                                                                <v-col md="8">{{ speObj.TutoringTask.modalities }}</v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-row>
                                                <v-row>
                                                    <v-card>
                                                        <v-card-text>
                                                            <v-row>
                                                                <v-col md="3">
                                                                    <span class="text-subtitle-1 ml-5">Commentaires</span>
                                                                </v-col>
                                                                <v-divider vertical></v-divider>
                                                                <v-col md="8">
                                                                    <v-textarea
                                                                        class="textStyleInput"
                                                                        placeholder="Ecrivez ici un commentaire"
                                                                        :value="speObj.TutoringTask.comment"
                                                                        @change="updateValue(speObj.TutoringTask.id, 'comment', $event)"
                                                                        rows="1"
                                                                        auto-grow
                                                                    ></v-textarea>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <next-page-button></next-page-button>
    </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import PageBanner from "@/components/PageBanner";
import FormationHeader from "@/components/designer/FormationHeader";
import NextPageButton from "../../../components/NextPageButton";

export default {
    name: "Tutoring",

    components: {
        NextPageButton,
        PageBanner,
        FormationHeader,
    },

    created() {
        this.getSpecificObjectives(this.currentFormation);
    },

    computed: {
        ...mapState("formations", ["educationalObjectives", "operationalObjectives", "currentFormation"]),

        ...mapState("global", ["actorList"]),

        ...mapGetters("formations", ["getOperationalObjectivesByParentId", "getSpecificObjectivesByParentId"]),
    },

    methods: {
        ...mapActions("formations", ["modifyTutoringTask", "getSpecificObjectives"]),

        updateValue: function(id, element, value) {
            this.modifyTutoringTask({
                id: id,
                [element]: value,
            });
        },
    },
};
</script>

<style scoped>
.v-card {
    width: 100%;
}

.textStyleInput >>> .v-input__slot::before,
.textStyleInput >>> .v-input__slot::after {
    content: none;
}
</style>
